import dayJs, { Dayjs } from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { capitalize } from "lodash"
import {
  DateFilterLabels,
  FieldLabels,
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultLocale,
  defaultTimezone,
} from "../constants"
import { MenuItemKeyValuePair } from "../types"

dayJs.extend(utc)
dayJs.extend(timezone)

export const toDayJs = (
  date?: string | number | Dayjs,
  sourceFormatter?: string,
) => {
  return typeof date === "string"
    ? dayJs(date, sourceFormatter).tz(defaultTimezone).locale(defaultLocale)
    : typeof date === "number"
      ? dayJs(date).tz(defaultTimezone).locale(defaultLocale)
      : date
}

export const toDate = (
  date?: string | Dayjs | number,
  formatter = defaultDateFormat,
  sourceFormatter?: string,
) => {
  const _date = toDayJs(date, sourceFormatter)
  if (!_date) return null
  if (_date.isValid()) {
    return _date.format(formatter)
  } else {
    return "-"
  }
}

export const toDateTime = (
  date?: string | Dayjs,
  formatter = defaultDateTimeFormat,
  sourceFormatter?: string,
) => {
  const _date = toDayJs(date, sourceFormatter)
  if (!_date) return null
  if (_date.isValid()) {
    return `${_date.format(formatter)} NZT`
  } else {
    return "-"
  }
}

export const toISOtime = (date?: string | Dayjs) => {
  const _date = toDayJs(date)
  if (!_date) return null
  if (_date.isValid()) {
    return _date.toISOString()
  } else {
    return null
  }
}

export const toUTCtime = (date?: string): string | null => {
  if (!date) {
    return null
  }

  const _date = new Date(date)
  if (!_date) return null
  const day = _date.getUTCDate().toString().padStart(2, "0")
  const month = new Intl.DateTimeFormat("en-US", {
    month: "short",
    timeZone: "UTC",
  }).format(_date)
  const year = _date.getUTCFullYear()
  const hours = _date.getUTCHours().toString().padStart(2, "0")
  const minutes = _date.getUTCMinutes().toString().padStart(2, "0")

  return `${day}/${month}/${year}, ${hours}:${minutes}`
}

export const toUTCDate = (
  date?: string,
  format: string = defaultDateFormat,
) => {
  const _date = toDayJs(date)
  if (!_date) {
    return ""
  }
  return _date.utc().format(format)
}

export const toNZTime = (date?: string): string | null => {
  if (!date) {
    return null
  }
  return toDateTime(date)
}

export const toCombinedVersion = (
  majorVersion?: number | string,
  minorVersion?: number | string,
) => `${majorVersion ?? ""}.${minorVersion ?? ""}`

export const toVersion = (version: string) => {
  const [majorVersion, minorVersion] = version.split(".")
  return {
    majorVersion: majorVersion ? parseInt(majorVersion) : 0,
    minorVersion: minorVersion ? parseInt(minorVersion) : 0,
  }
}

export const toDateLabelDisplay = (label: string) => {
  return DateFilterLabels[label]
}

export const toLabelDisplay = (label: string) => {
  return FieldLabels[label]
}

export const toCapitalize = (str = "") => {
  if (!str) return ""
  return capitalize(str.replaceAll("_", " "))
}

export const toKeyValuePairs = <T extends Record<keyof T, string>>(
  records: T,
) => {
  return Object.entries(records).map(([key, value]) => {
    return { value: key, label: value as string }
  })
}

export const toBooleanValue = (value: string) => {
  if (!value || value === "") {
    return null
  }

  return value.toLowerCase() === "yes" ? true : false
}

export const toBooleanDisplayValue = (value: boolean | null) => {
  if (value === null) {
    return null
  }

  return value ? "Yes" : "No"
}

export const toSearchValue = (
  menuItem: MenuItemKeyValuePair | MenuItemKeyValuePair[],
) => {
  const searchValue: MenuItemKeyValuePair = Array.isArray(menuItem)
    ? menuItem[0]
    : menuItem
  return searchValue?.value ?? null
}

export const toFirstNumber = (value: string) => {
  const matches = value.match(/(\d+)/)

  return matches ? parseInt(matches[0]) : null
}
