import dayJs, { Dayjs } from "dayjs"
import zod from "zod"

import { toDayJs } from "../convertor"

const dayjsInstance = zod.instanceof(dayJs as unknown as typeof Dayjs)

export const dateInput = zod
  .string()
  .datetime()
  .transform((v) => v && toDayJs(v))

export const dateOutput = dayjsInstance
  .refine((v) => v.isValid())
  .transform((v) => v.toISOString())

export const expiryDateSchema = zod.object({
  expiryDateStart: dayjsInstance.optional(),
  expiryDateEnd: dayjsInstance.optional(),
})

export const effectiveDateSchema = zod.object({
  effectiveDateStart: dayjsInstance.optional(),
  effectiveDateEnd: dayjsInstance.optional(),
})

export const lastEditedDateSchema = zod.object({
  lastEditedDateStart: dayjsInstance.optional(),
  lastEditedDateEnd: dayjsInstance.optional(),
})

export const booleanToStringTransformation = zod
  .boolean()
  .transform((v) => (v === null ? null : v ? "Yes" : "No"))

export const stringToBooleanTransformation = zod
  .string()
  .nullable()
  .transform((v) =>
    v === null || v === "" ? null : v === "Yes" ? true : false,
  )

export const stringNullSchema = zod
  .string()
  .nullable()
  .transform((v) => (v === "" ? null : v))

export const yesNoSchema = zod.union([
  booleanToStringTransformation,
  stringToBooleanTransformation,
])

export const yesNoValidation = (
  fieldValue: boolean | "Yes" | "No" | null,
  ctx: zod.RefinementCtx,
  path: string,
) => {
  if (fieldValue === null) {
    ctx.addIssue({
      code: zod.ZodIssueCode.custom,
      path: [path],
      message: "Please select Yes or No.",
    })
  }
}

export const dateSchema = zod
  .union([dateInput.nullable(), dateOutput.nullable()])
  .optional()

export const isDateRangeOverlap = ({
  startDate1,
  endDate1,
  startDate2,
  endDate2,
}: {
  startDate1: Dayjs
  endDate1: Dayjs
  startDate2: Dayjs
  endDate2: Dayjs
}) => {
  const isValidStartDate1 = startDate1.isValid()
  const isValidEndDate1 = endDate1.isValid()
  const isValidStartDate2 = startDate2.isValid()
  const isValidEndDate2 = endDate2.isValid()

  return (
    (isValidStartDate1 &&
      isValidStartDate2 &&
      isValidEndDate1 &&
      isValidEndDate2 &&
      endDate2.diff(startDate1, "day") >= 0 &&
      endDate1.diff(startDate2, "day") >= 0) ||
    (isValidStartDate1 &&
      !isValidEndDate1 &&
      isValidEndDate2 &&
      endDate2.diff(startDate1, "day") >= 0) ||
    (isValidStartDate1 &&
      isValidEndDate1 &&
      !isValidEndDate2 &&
      endDate1.diff(startDate2, "day") >= 0) ||
    (!isValidEndDate1 && !isValidEndDate2) ||
    (!isValidStartDate1 && !isValidStartDate2) ||
    (isValidEndDate2 &&
      isValidStartDate1 &&
      !isValidStartDate2 &&
      endDate2.diff(startDate1, "day") >= 0) ||
    (!isValidEndDate2 &&
      !isValidStartDate1 &&
      isValidStartDate2 &&
      isValidEndDate1 &&
      endDate1.diff(startDate2, "day") >= 0) ||
    (isValidEndDate1 &&
      isValidEndDate2 &&
      !isValidStartDate1 &&
      endDate1.diff(endDate2, "day") >= 0)
  )
}
