import zod from "zod"
import { DownloadImportCertificatePdfReason } from "../types"

export const downloadImportCertificatePdfReasonFormSchema = zod
  .object({
    downloadPdfReason: zod.string(),
    otherReason: zod.string().optional(),
  })
  .superRefine(({ downloadPdfReason, otherReason }, ctx) => {
    const reasonType =
      DownloadImportCertificatePdfReason[
        downloadPdfReason as keyof typeof DownloadImportCertificatePdfReason
      ]

    if (
      reasonType === DownloadImportCertificatePdfReason.Other &&
      otherReason === ""
    ) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        message: "Please specify other reason",
        path: ["otherReason"],
      })
    }
  })
