import {
  ImportCertificateLogStatus,
  ImportCertificateStatus,
} from "@tc/graphql-server"

export const downloadFile = (
  url: string,
  fileName: string,
  fileType: string,
) => {
  const link = document.createElement("a")
  link.href = url

  if (fileName && fileType) {
    link.setAttribute("download", `${fileName}.${fileType}`)
  }

  // Append to html link element page
  document.body.appendChild(link)
  // Start download
  link.click()
  // Clean up and remove the link
  link.parentNode?.removeChild(link)
}

export const copyToClipboard = (text: string) => {
  if (!navigator?.clipboard) {
    console.warn("Clipboard API not available")
    return false
  }
  return navigator.clipboard
    .writeText(text)
    .then(() => true)
    .catch(() => false)
}

export type Func<T = unknown> = (...args: T[]) => unknown
export const pipePromises =
  (...fns: Func[]) =>
  (params?: unknown) =>
    fns.reduce((p, fn) => p.then(fn), Promise.resolve(params))

export const pipe =
  (...fns: Func[]) =>
  (initialValue: unknown) =>
    fns.reduce((result, func) => func(result), initialValue)

export const GetStatusLabel = (status: ImportCertificateStatus) => {
  switch (status) {
    case ImportCertificateStatus.ReplacementRequested:
      return "Replacement requested"

    case ImportCertificateStatus.Rejected:
      return "Rejected"

    case ImportCertificateStatus.Approved:
      return "Approved"
    case ImportCertificateStatus.Issued:
      return "Issued"
    case ImportCertificateStatus.Replaced:
      return "Replaced"
    case ImportCertificateStatus.Unrecognised:
      return "Unrecognised"
    case ImportCertificateStatus.Withdrawn:
      return "Withdrawn"

    default:
      return ""
  }
}

export const GetCertificateLogStatusLabel = (
  status: ImportCertificateLogStatus,
) => {
  switch (status) {
    case ImportCertificateLogStatus.Acknowledged:
      return "Acknowledged"

    case ImportCertificateLogStatus.PdfDownloaded:
      return "PdfDownloaded"

    default: {
      const certStatus = status.toString() as ImportCertificateStatus
      return GetStatusLabel(certStatus)
    }
  }
}

export const isValidNumberString = (
  value: string,
  required?: boolean,
): boolean => {
  if (!required && !value) {
    return true
  }

  const numberRegex = /^-?\d*\.?\d*$/
  return numberRegex.test(value)
}
