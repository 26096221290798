import zod from "zod"

import {
  BusinessArea,
  CertificateType,
  DestinationMarket,
  ImportCertificateType,
  TemplateVersionStatus,
} from "@tc/graphql-server"
import { isEmpty } from "lodash"
import {
  dateInput,
  dateOutput,
  effectiveDateSchema,
  expiryDateSchema,
  lastEditedDateSchema,
} from "./shared"

export const searchTemplateSchema = zod.object({
  q: zod.string().optional(),
  commodity: zod.string().array().optional(),
  destinationMarket: zod.string().array().optional(),
  status: zod.string().array().optional(),
  dateFilterType: zod.string().optional(),
  dateFilters: zod
    .array(
      zod.union([expiryDateSchema, effectiveDateSchema, lastEditedDateSchema]),
    )
    .optional(),
})

export const templateDetailsFormSchema = zod.object({
  code: zod.string().min(3, { message: "Please enter Template Code" }),
  description: zod.string(),
  displayedDescription: zod.string(),
  version: zod.string().regex(/^\d+(\.\d+)?$/, {
    message: "Please enter version format (Major.Minor) in numeric value",
  }),
  title: zod.string(),
  status: zod.nativeEnum(TemplateVersionStatus, {
    errorMap: () => {
      return { message: "Please select Status" }
    },
  }),
  businessArea: zod.nativeEnum(BusinessArea, {
    errorMap: () => {
      return { message: "Please select Business Area" }
    },
  }),
  commodity: zod
    .string()
    .optional()
    .nullable()
    .transform((value) => (isEmpty(value) ? null : value)),
  destinationMarket: zod.nativeEnum(DestinationMarket, {
    errorMap: () => {
      return { message: "Please select Destination Market" }
    },
  }),
  templateType: zod.string(),
  certificateType: zod.union([
    zod.nativeEnum(CertificateType, {
      errorMap: () => {
        return { message: "Please select Certificate Type" }
      },
    }),
    zod.nativeEnum(ImportCertificateType, {
      errorMap: () => {
        return { message: "Please select Certificate Type" }
      },
    }),
  ]),
  id: zod.string(),
  expire: zod.union([dateInput.nullable(), dateOutput.nullable()]).optional(),
  effective: zod
    .union([dateInput.nullable(), dateOutput.nullable()])
    .optional(),
  createdAt: zod.string(),
  updatedAt: zod.string(),
  updatedBy: zod.string(),
  documentId: zod.string().nullish(),
})
