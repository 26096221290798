import zod from "zod"
import { RowState } from "../types"

export const decisionTableBaseSchema = zod.object({
  description: zod.string(),
  state: zod.nativeEnum(RowState).nullish(),
})

export const searchBusinessRulesSchema = zod.object({
  q: zod.string().optional(),
})
